import React from "react"
import Navigation from "../components/Navigation"
import styled from "styled-components"
import FadeInSection from "../components/FadeInSection"
import GatsbyLink from "gatsby-link";

// markup
const AboutPage = () => {
  return (
    <Container>
     <Navigation visible to={"/"} />
      <Main>
        <title>Solace</title>
        <MainContainer>
          <FadeInSection>
            <i>Solace</i> is a short story written collaboratively by Zach McLane and the text-generation AI GPT-3, created by OpenAI. GPT-3 responds to text by continuing where a given entry ends, attempting to match the form and style of whatever text it has been provided. <i>Solace</i> began during the summer of 2020, and developed through inputting notes, journal entries, and fragments of writing into GPT-3. The texts produced by GPT-3 were then incorporated with the original texts, which were then re-input into GPT-3 and edited together as a story took shape. 
          </FadeInSection>
          <FadeInSection>
            <Links>
              <People>
                <Person>
                  <LeftSection>
                    Solace is a project by Zach McLane |&nbsp;<Link href="http://zachmclane.com" rel="noopener noreferrer" target="_blank">zachmclane.com</Link>
                  </LeftSection>
                </Person>
                <Person>
                  <LeftSection>
                    Illustrations by Elena Pérez |&nbsp;<Link href="https://elena4prez.bigcartel.com/" rel="noopener noreferrer" target="_blank">elena4prez.bigcartel.com</Link> 
                  </LeftSection>
                </Person>
                <Person>
                  <LeftSection>
                    Web design by Alissa Niewiadomski |&nbsp;<Link href="https://instagram.com/domskiiz/" rel="noopener noreferrer" target="_blank">@domskiiz</Link>
                  </LeftSection>
                </Person>
              </People>
              <ImgContainer>
                <AboutPageImage src={'/solace-2.png'} alt="About page illustration of a flower, with 10 petals comprised of rectangular structured addressed envelopes outlined in binary"/>
              </ImgContainer>
            </Links>
          </FadeInSection>
          <div style={{ "margin": "80px", "marginBottom": "32px", "fontSize": "12px", "fontFamily":"acumin-pro, sans-serif"}}>
              Copyright 2022 © All rights reserved.
          </div>
        </MainContainer>
      </Main>
    </Container>
  )
}

const AboutPageImage = styled.img`
  width: 100%;
  height: auto;
  @media (max-width: 800px) {
    margin-top: 32px;
  }
`;

const People = styled.div`
  flex: 1;
`

const LeftSection = styled.div`
  font-size: 14px;
`;

const ImgContainer = styled.div`
  flex: 1
`;


const Person = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 30px;
  font-size: 16px;

  align-self: flex-start;
`

const Container = styled.div`
  position: relative;
`;

const Main = styled.main`
  color: black;
  padding: 96px;
  background-color: #fffdf7;

  @media (max-width: 500px) {
    padding: 96px 24px;
    font-size: 18px;
  }
`;

const Link = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: black;

  :hover {
    color: lightcoral;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
`;  

const Links = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: row;

  @media (max-width: 800px) {
    flex-direction: column;

  }
  @media (max-width: 500px) {
    margin-top:0px;
  }
`


export default AboutPage;
